<template>
  <div class='acc-valid-email-main-wrapper'>
    <a-form>
      <a-row style='display: unset'>
        <a-col>
          <div class='acc-valid-email-title' style='margin-top: 100px'>
            Account Validation
            <mail-outlined
              style='
                margin-left: 0.5rem;
                font-size: 1.5rem;
                color: rgba(0, 0, 0, 0.85);
                vertical-align: inherit;
              '
            />
          </div>
          <div class='acc-valid-email-title-description'>
            Your email ID is already linked with one trading account
          </div>
          <div class='acc-valid-email-sub-title-description'>
            Open another trading account for your<br />
            family member with the same email ID?
          </div>
          <div class='acc-valid-email-family-radio-btns'>
            <a-radio-group v-model:value='openNewAccount' name='radioGroup'>
              <a-radio value='yes'>Yes</a-radio>
              <a-radio value='no'>No</a-radio>
            </a-radio-group>
          </div>
          <div
            v-if='openNewAccount === "yes"'
            class='acc-valid-email-family-member-wrapper'
          >
            <div>
              <a-form-item v-bind='validateInfos.relation'>
                <a-select
                  class='select-field'
                  v-model:value='modelRef.relation'
                  placeholder='Specify your relation with family member'
                  :options='relationshipOptions'
                  style='width: 391px; text-align: initial'
                ></a-select>
              </a-form-item>
            </div>
          </div>
          <div v-else class='acc-valid-email-family-member-wrapper'>
            <div>
              <a-select
                class='select-field'
                v-model:value='relation'
                placeholder='Specify your relation with family member'
                disabled
                style='width: 391px; text-align: initial'
              ></a-select>
            </div>
          </div>
          <div class='acc-valid-email-btn-wrapper'>
            <a-button type='primary' @click='onClickSubmit'>Submit</a-button>
          </div>
        </a-col>
      </a-row>
    </a-form>
  </div>
</template>
<script>
// import { useRouter } from 'vue-router';
import { onMounted, ref, reactive } from 'vue';
import { MailOutlined } from '@ant-design/icons-vue';
import { Form } from 'ant-design-vue';
import { useStore } from 'vuex';
import services from '../../../services/apis';

const { useForm } = Form;

export default {
  components: {
    MailOutlined,
  },
  setup() {
    // const router = useRouter();
    const store = useStore();
    const relationshipOptions = ref([
      {
        value: 'FATHER',
        label: 'Father',
      },
      {
        value: 'MOTHER',
        label: 'Mother',
      },
      {
        value: 'SISTER',
        label: 'Sister',
      },
      {
        value: 'BROTHER',
        label: 'Brother',
      },
    ]);
    const openNewAccount = ref('yes');
    onMounted(() => {
      window.scrollTo(0, 0);
      // services
      //   .getStages()
      //   .then((response) => {
      //     store.dispatch('onboardingStore/updateStage', response.data.data.stage);
      //   })
      //   .catch(() => {
      //   });
    });
    const modelRef = reactive({
      relation: undefined,
    });
    const rulesRef = reactive({
      relation: [
        {
          required: true,
          message: 'Please Select',
        },
      ],
    });

    const { resetFields, validate, validateInfos } = useForm(
      modelRef,
      rulesRef,
    );

    const yesSelected = true;

    const onClickSubmit = () => {
      // router.push('/lead/info');
      if (openNewAccount.value === 'yes') {
        validate().then(() => {
          const data = {
            openNewAccount: openNewAccount.value === 'yes' ? yesSelected : !yesSelected,
            relation: openNewAccount.value === 'yes' ? modelRef.relation : '',
          };

          services
            .accountValidation(data)
            .then((response) => {
              // const { data } = response;
              store.dispatch('onboardingStore/updateStage', response.data.data.stage);
              // router.push('/pan/details');
            })
            .catch(() => {});
        });
      } else {
        const data = {
          openNewAccount: openNewAccount.value === 'yes' ? yesSelected : !yesSelected,
        };

        services
          .accountValidation(data)
          .then((response) => {
            // const { data } = response;
            store.dispatch('onboardingStore/updateStage', response.data.data.stage);
            // router.push('/pan/details');
          })
          .catch(() => {});
      }
    };

    return {
      openNewAccount,
      onClickSubmit,
      relationshipOptions,
      validateInfos,
      resetFields,
      modelRef,
      yesSelected,
    };
  },
};
</script>

<style lang='scss'>
@import '../styles/AccountValidationEmail.scss';
</style>
